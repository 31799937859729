export const tetrisConfig = {
    stage: {
        width: 10,
        height: 20,
    },
    cell: {
        size: 32,
        spacing: 0,
    },
};
